<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="liabilities"
                          base-table="incoming_invoice"
                          columnsPrefix="incoming_invoice.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="common.menu.account-book.liabilities"
                          :actions-list="getActions()"
                >
                    <template v-slot:top-actions>
                        <b-form inline class="mb-2">
                            <font-awesome-icon
                                role="button"
                                icon="chevron-left"
                                @click="prevMonth()"
                            />&nbsp;
                            <b-form-select
                                v-model="month"
                                :options="monthsList()"
                                class="mr-2"
                                @change="monthChanged"
                            ></b-form-select>
                            <font-awesome-icon
                                role="button"
                                icon="chevron-right"
                                @click="nextMonth()"
                            />

                            <b-form-checkbox
                                class="ml-2"
                                v-model="includePaid"
                                switch
                                value="1"
                                unchecked-value="0"
                                @change="includePaidChanged"
                            >{{ $t('incoming_invoice.label.include_paid') }}
                            </b-form-checkbox>
                        </b-form>
                    </template>

                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                @click="openIncomingInvoice(row.item,row.item.type)">
                                <font-awesome-icon class="mr-2" icon="edit"/>
                                {{ $t('common.title.edit') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="deleteIncomingInvoice(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="trash"/>
                                {{ $t('common.title.delete') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import tableDateFilter from '@/bundles/erika_common_bundle/mixins/table-date-filter'

export default {
    name: 'Liabilities',
    mixins: [commonTable, tableDateFilter],
    data() {
        return {
            includePaid: false,
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('IncomingInvoices', ['getLiabilitiesTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'IncomingInvoices/fetchLiabilities', 'getLiabilitiesTable', {}, (tableData) => {
                if (tableData.params.filter.include_paid) {
                    this.includePaid = tableData.params.filter.include_paid
                }
            });
        },
        ctxAdditionalParams() {
            return {
                'include_paid':  this.includePaid
            }
        },
        deleteIncomingInvoice(id) {
            this.$removeConfirm('IncomingInvoices/deleteIncomingInvoice', id, this.refreshTable)
        },
        deleteSelected() {
            this.$removeConfirm('IncomingInvoices/deleteIncomingInvoices', this.selected.map(row => row.id), this.refreshTable)
        },
        openIncomingInvoice(incomingInvoice, type) {
            this.$root.$children[0].openModal('incoming-invoices-modal', {
                    id: incomingInvoice.id,
                    type: type,
                }, this.refreshTable,
                {}
            )
        },
        getActions() {
            return [
                {
                    title: this.$t('common.button.create_incoming_invoice'),
                    icon: 'plus',
                    hide: !this.$root.hasAccess('incoming-invoice', 'create'),
                    click: () => {
                        this.openIncomingInvoice({}, 'eingang')
                    }
                },
                {
                    title: this.$t('common.button.delete_selected'),
                    icon: 'trash',
                    disabled: !this.selected.length,
                    click: () => {
                        this.deleteSelected()
                    }
                },
            ]
        },
        includePaidChanged(val) {
            this.$set(this.filter, 'include_paid', val)
            this.$set(this.filter, 'filterChanged', true)
        }
    },
}
</script>